// COMP WRAPPER STYLES// COMP WRAPPER STYLES
.compwrapper-container {
    padding-left: 20vw;
    padding-right: 36px;
    padding-bottom: 60px;
    @include responsive-for(xlg) {
      padding-left: 28vw;
    }

    @include responsive-for(lg) {
      padding-left: 4vw;
      padding-right: 4vw;
    }
  }