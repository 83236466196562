// SIDE NAVIGATION STYLES

.sidenavigation-mobile-container {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: none;

    @include responsive-for(lg) {
      display: block;
    }
  
    .sidenavigation-mobile {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 80vw;
      background: $white;
      border-right: 1px solid $grey;
      padding: 0px 20px;
      box-shadow: 6px 0px 46px rgba(0,0,0,0.08);
      overflow-y: auto;
            /* width */
            &::-webkit-scrollbar {
              width: 10px;
            }
      
            /* Track */
            &::-webkit-scrollbar-track {
              background-color: $light_grey;
            }
            
            /* Handle */
            &::-webkit-scrollbar-thumb {
              background:$grey;
              border-radius: 10px;
            }
      
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background:darken($grey, 5%); 
            }
      .close-sidenav {
        position: absolute;
        right: 20px;
        top: 45px;
        font-size: 30px;
        cursor: pointer;
      }
    }
  }
  
  .sidenavigation-web {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 18vw;
    background: $white;
    border-right: 1px solid $grey;
    padding: 0px 20px;
    display: block;
  overflow-y: auto;
        /* width */
        &::-webkit-scrollbar {
          width: 10px;
        }
  
        /* Track */
        &::-webkit-scrollbar-track {
          background-color: $light_grey;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background:$grey;
          border-radius: 10px;
        }
  
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background:darken($grey, 5%); 
        }
    @include responsive-for(xlg) {
      width: 26vw;
    }
  
    @include responsive-for(lg) {
      display: none;
    }
  }
  
  .sidenavigation-container {
    .sidenavigation {
      padding-top: 10px;
  
      &-header {
        img {
          width: 160px;
        }
      }
  
      &-list {
        list-style: none;
        padding-left: 20px;
        margin-top: 40px;
  
        @include responsive-for(lg) {
          margin-top: 10px;
        }
        
        &-item {
          a {
            display: block;
            padding: 15px 0px;
            display: flex;
            align-items: center;
            text-decoration: none;
            color: $secondarytextcolor;
            transition: color .2s, padding-left .2s;
            position: relative;
  
            &::after {
              position: absolute;
              content: "";
              width: 7px;
              height: 7px;
              border-radius: 50%;
              left: 0;
              top: 50%;
              background: $nt_default;
              transform: translate(-4px, -50%);
              opacity: 0;
              transition: opacity .2s, transform .2s;
            }
  
            &:hover {
              color: $black;
              padding-left: 20px;
  
              &::after {
                opacity: 1;
                transform: translate(0px, -50%);
              }
            }
  
            &.active {
              color: $black;
              padding-left: 20px;
  
              &::after {
                opacity: 1;
                transform: translate(0px, -50%);
              }
            }
  
            .icon {
              font-size: $extrasmall;
              position: relative;
              top: 2px;
            }
  
            .title {
              font-family: $avertaSemiBold;
              padding-left: 10px;
            }
          }
        }
      }
    }
  }
  
  // SIDE NAV ANIMATION 
  .sidenavigation-node {
    &-enter {
      opacity: 0;
      transform: translateX(-100%);
  
      &-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 200ms, transform 200ms;
      }
    }
  
    &-exit {
      opacity: 1;
      transform: translateX(0);
  
      &-active {
        opacity: 0;
        transform: translateX(-100%);
        transition: opacity 200ms, transform 200ms;
      }
    }
  }