// COLORS
$defaultcolor: #3180A9;
$teal: #3180A9;
$orange: #FF8144;
$green: #5B9540;
$purple: #A259F4;
$light_teal: #11A7AF;
$white: #FFFFFF;
$black: #353535;
$red: #FF4343;
$blue: #2480F5;
$light_black: #A1A1A1;
$grey: #E1E1E1;
$light_grey: #F8F8F8;

$nt_default: #59C2E2;
$nt_darkdefault: #3895B1;

// TEXT COLORS
$defaulttextcolor: #555555;
$lighttextcolor: #707070;
$secondarytextcolor: #989898;

// BORDER COLORS
$defaultborder: #E1E1E1;

// FONT FAMILIES
$avertaLight: AvertaLight;
$avertaSemiBold: AvertaSemiBold;
$avertaBold: AvertaBold;
$avertaExtraBold: AvertaExtraBold;

// FONT SIZES
// DEFAULT => 16px
// 12px => 0.75rem
// 20px => 1.25rem
// 24px => 1.5rem
// 28px => 1.75rem
// 32px => 2rem
// 38px => 2.375rem
// 80px => 5rem
$extralarge: 3.5rem;
$large: 2.375rem;
$medium: 2rem;
$small: 1.75rem;
$extrasmall: 1.5rem;
$thin: 1.25rem;
$extrathin: 1rem;
$hairline: 0.75rem;

// WIDTH
$default_width: 85%;